import React from "react";
import { Button, Divider, Grid } from "@mui/material";
import IconButton from "../components/IconButton";
import { useLocation } from "react-router";
import { agencyMenu, farmerMenu } from "../utils/menus";
import { topText } from "../utils/topTitles";
import MyMap from "../pages/MyMap";
import { useNavigate } from "react-router";
import LargeButton from "./LargeButton";

const getType = (profileNumber) => {
  switch (profileNumber) {
    case 0:
      return "adminAgence";
    case 1:
      return "conseillerAgence";
    case 2:
      return "partenaireAgence";
    case 3:
      return "agentBureauAgence";
    case 4:
      return "petitProducteur";
    case 5:
      return "admin";
    default:
      break;
  }
};

const TYPE = getType(JSON.parse(localStorage.getItem("data"))?.profil) || {};

const getMenu = (type) => {
  switch (type) {
    case "admin":
      return agencyMenu;
    case "adminAgence":
      return farmerMenu;
    default:
      return agencyMenu;
  }
};

const logout = () => {
  localStorage.clear();
  window.location.href = "/";
};

function SideBars({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item sm={2.5} style={{ backgroundColor: "#F1F1F1" }}>
        <img
          src="/assets/images/logo.png"
          alt="logo"
          style={{ marginLeft: 10 }}
        />
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {getMenu(TYPE).map((item, i) => (
            <IconButton
              key={i.toString()}
              icon={item.icon}
              link={item.link}
              state={item.state}
              title={item.title}
              isActive={
                location.pathname === item.link ||
                item.isActiveIn.includes(location.pathname) ||
                location.pathname.includes(item.link)
              }
            />
          ))}
        </div>
      </Grid>
      <Grid item sm={0.01} style={{ backgroundColor: "#D4D4D4" }} />
      <Grid item sm={7}>
        <Grid container>
          <Grid
            item
            sm={12}
            style={{
              height: 100,
              position: "relative",
              padding: 10,
              paddingLeft: 20,
            }}
          >
            <h1 style={{ fontSize: 20 }}>
              {topText[location.pathname] && topText[location.pathname].title}
            </h1>
            <Divider
              style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
            />
          </Grid>
          <Grid item sm={12} style={{ padding: 10 }}>
            {children}
            <div style={{minHeight: "60vh"}}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={0.01} style={{ backgroundColor: "#D4D4D4" }} />
      <Grid item sm={2.48}>
        <LargeButton
          title={"Me déconnecter"}
          style={{
            fontSize: 15,
            width: "70%",
            height: 40,
            backgroundColor: "red",
          }}
          action={logout}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            flexDirection: "row",
            marginTop: 20,
          }}
        >
          <img
            src="/assets/images/map.png"
            onClick={() =>
              navigate("/map", {
                state: {
                  allFarmers: true,
                },
              })
            }
            style={{ width: 200, height: 200 }}
          />
        </div>
      </Grid>
      <Grid
        item
        sm={12}
        style={{
          height: 100,
          position: "relative",
          padding: 10,
          paddingLeft: 20,
          backgroundColor: "#FFFFFF",
          border: "solid #D4D4D4 .5px",
          borderBottomWidth: 0,
          borderRightWidth: 0,
          borderLeftWidth: 0,
        }}
      >
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
            color: "#7A7A7A",
            marginTop: 40,
          }}
        >
          © 2021 Tolbi | <span style={{cursor: "pointer",color: "#55AE58"}} onClick={() => window.open("/terms","_blank") }>Terms | Privacy</span>
        </p>
        <Divider
          style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
        />
      </Grid>
    </Grid>
  );
}

export default SideBars;
