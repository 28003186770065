export const members = [
    {
        title: "Administrateurs",
        logoUrl: "/assets/images/admin.png",
        type: "ADMIN"
    },
    {
        title: "Conseillers agricoles",
        logoUrl: "/assets/images/conseilleragri.png",
        type: "CONSEILLER_AGRICOLE"
    },
    {
        title: "Agents de bureau",
        logoUrl: "/assets/images/agentBureau.png",
        type: "PARTENAIRE"
    },
    {
        title: "Partenaires",
        logoUrl: "/assets/images/partenaire.png",
        type: "AGENT_BUREAU"
    },
]