import { FormControlLabel, Grid, Radio } from "@mui/material";
import React from "react";

export default function CustomRadio({
  onChange = () => {},
  value,
  label,
  sm = 10,
  checked=false
}) {
  return (
    <Grid sm={sm}>
      <FormControlLabel
        checked={checked}
        control={<Radio value={value} onChange={onChange} />}
        label={label}
      />
    </Grid>
  );
}
