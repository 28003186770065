import { Button } from '@mui/material'
import React from 'react'
import { FaPlus } from 'react-icons/fa'

export default function AddButton({display=true,title}) {
    return (
        <Button
            startIcon={<FaPlus size={12} />}
            variant="contained"
            size="large"
            style={{
                color: "white",
                backgroundColor: "#55AE58",
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: "none",
                height: 40,
                fontSize: 10,
                width: 160,
                display: display ? "": "none"
              }}
            >
              {title}
            </Button>
    )
}
