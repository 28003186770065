import React from 'react'
import SideBars from '../components/SideBars'

function Services() {
    return (
        <SideBars>
        <h1>Services</h1>
    </SideBars>
    )
}

export default Services
