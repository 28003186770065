import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

export default function GoBackBtn({to=-1, state={}}) {
    return (
        <NavLink to={to} state={state}>
            <div style ={{width: 40, height: 40, backgroundColor: "#55AE58", borderRadius: 20,display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", right: 30,top: 30}}>
                 <FaArrowLeft color="white"/> 
            </div>
        </NavLink>
    )
}
