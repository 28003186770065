import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { CAMPAIN } from '../endpoints'

const initialState = {
  loading: false,
  addCampainData: null,
  addCampainError: null,
  getCampainsData: null,
  getCampainsError: null,
}

export const addCampain = createAsyncThunk(
    'campain/add',
    async (data,{rejectWithValue}) => {
      try {
        const res = await axios.post(CAMPAIN+data.properties?.farmer?.id+"/campagnes/",data, {
            headers:{
                Authorization: "Bearer "+ JSON.parse(localStorage.getItem('data')).access
            }
        })
    return res.data
        
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error)
      }
  })


  export const getCampains = createAsyncThunk(
    'campain/get',
    async (data,{rejectWithValue}) => {
      try {
        const res = await axios.get(CAMPAIN+data.farmerId+"/campagnes/", {
            headers:{
                Authorization: "Bearer "+ JSON.parse(localStorage.getItem('data')).access
            }
        })
    return res.data
        
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error)
      }
  })
 

export const campainSlice = createSlice({
  name: 'campain',
  initialState,
  reducers: {
    
  },
  extraReducers: {
      [addCampain.pending]: (state) => {
        state.loading = true
        state.addCampainData = null
        state.addCampainError = null
      },
      [addCampain.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.addCampainData = payload
      },
      [addCampain.rejected]: (state,{payload}) => {
        state.loading = false
        state.addCampainError = payload
      },
      [getCampains.pending]: (state) => {
        state.loading = true
        state.getCampainsData = null
        state.getCampainsError = null
      },
      [getCampains.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.getCampainsData = payload
      },
      [getCampains.rejected]: (state,{payload}) => {
        state.loading = false
        state.getCampainsError = payload
      },
      
      
  }
})

// Action creators are generated for each case reducer function
export const campainReducer = campainSlice.reducer