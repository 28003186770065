import React, { useEffect, useState } from "react";
import Form from "../components/Form";
import SideBars from "../components/SideBars";
import Input from "../components/Input";
import LargeButton from "../components/LargeButton";
import Select from "../components/Select";
import FormTextSeparator from "../components/FormTextSeparator";
import { Grid, Button } from "@mui/material";
import CustomCheckbox from "../components/CustomCheckbox";
import CustomRadio from "../components/CustomRadio";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFarmer } from "../slices/farmerSlice";
import { addCampain } from "../slices/campainSlice";
import RoundedItem from "../components/RoundedItem";

const amendements = [
  {
    name: "Organique",
    value: "MATIERE_ORGANIQUE",
  },
  {
    name: "Minérale",
    value: "MATIERE_MINERALE",
  },
];

const sources_eau = [
  {
    name: "Puit",
    value: "NAPPE_PUIT",
  },
  {
    name: "Forage",
    value: "NAPPE_FORAGE",
  },
  {
    name: "Fleuve",
    value: "FLEUVE",
  },
  {
    name: "Robinet",
    value: "ROBINET",
  },
  {
    name: "Inexistant",
    value: "INEXISTANT",
  },
];


const engrais = [
  {
    name: "Azote",
    value: "AZOTE",
  },
  {
    name: "Phosphate",
    value: "PHOSPHATE",
  },
  {
    name: "Potasse",
    value: "POTASSIQUE",
  },
  {
    name: "NPK",
    value: "NPK",
  },
  
];



const situ_mat = [
  {
    name: "Celibataire",
    value: "CELIBATAIRE",
  },
  {
    name: "Marié",
    value: "MARIE",
  },
  {
    name: "Divorcé",
    value: "DIVORCE",
  },
  {
    name: "Veuf",
    value: "VEUF",
  },
];


const petit_mat = [
  {
    name: "Brouette",
    value: "BROUETTE",
  },
  {
    name: "Balais",
    value: "BALAIS",
  },
  {
    name: "Semoir manuel",
    value: "SEMOIR_MANUEL",
  },
  {
    name: "Secateur manuel",
    value: "SECATEUR_MANUEL",
  },
  {
    name: "Houe Sine",
    value: "HOUE_SINE",
  },
  {
    name: "Hilaire daba",
    value: "HILAIRE_DABA",
  },
  {
    name: "Rateau",
    value: "RATEAU",
  },
  {
    name: "Bineuse manuelle",
    value: "BINEUSE_MANUELLE",
  },
  {
    name: "Arara",
    value: "ARARA",
  },
];


const mat_lourd = [
  {
    name: "Tracteur",
    value: "TRACTEUR",
  },
  {
    name: "Motoculteur",
    value: "MOTOCULTEUR",
  },
  {
    name: "Ensileuse",
    value: "ENSILEUSE",
  },
  {
    name: "Herse",
    value: "HERSE",
  },
  {
    name: "Decompacteur",
    value: "DECOMPACTEUR",
  },
  {
    name: "Pulverisateur",
    value: "PULVERISATEUR",
  },
  {
    name: "Faucheuse",
    value: "FAUCHEUSE",
  },
  {
    name: "Déchaumeur",
    value: "DECHAUMEUR",
  },
  {
    name: "Moissoneuse Batteuse",
    value: "MOISONNEUSE_BATTEUSE",
  },
  {
    name: "Semoir pneumatique",
    value: "SEMOIR_PNEUMATIQUE",
  },
  {
    name: "Epandeur engrais",
    value: "EPANDEUR_ENGRAIS",
  },
  {
    name: "Semoir pertilisateur",
    value: "SEMOIR_FERTILISATEUR",
  },
  {
    name: "Planteuse",
    value: "PLANTEUSE",
  },
  {
    name: "Aracheuse",
    value: "ARRACHEUSE",
  },
  {
    name: "Motobineuse",
    value: "MOTOBINEUSE",
  },
  {
    name: "Bineuse Fertiliseur",
    value: "BINEUSE_FERTILISEUR",
  },
  {
    name: "Charrue",
    value: "CHARRUE",
  },
  {
    name: "Faneuse",
    value: "FANEUSE",
  },
  {
    name: "Arara",
    value: "FAUCHEUSE_HACHEUSE",
  },
  {
    name: "Sous soleuse",
    value: "SOUS_SOLEUSE",
  },
  {
    name: "Semoir Mecanique",
    value: "SEMOIR_MECANIQUE",
  },
];
const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output.map((i) => {
    return { name: i.toString(), value: i.toString() };
  });
};

const getTitle = (type) => {
  switch (type) {
    case "add":
      return "Remplir les informations";
    case "update":
      return "Modifier les informations";
    default:
      break;
  }
};

const getBtnText = (type) => {
  switch (type) {
    case "add":
      return "Créer la campagne";
    case "update":
      return "Mettre à jour les informations";
    default:
      break;
  }
};

const Campain = ({ type = "add" }) => {
  const [myErrors, setMyErrors] = useState("");

  const location = useLocation();

  const [properties, setProperties] = useState({
    plante: "",
    nombre_ouvriers: "",
    source_eau: "",
    amendement: "",
    est_semence_certifie: true,
    rendement: "",
    type_engrais: "",
    petits_materiels: [
 
    ],
    materiels_lourds: [

    ],
    date_debut: "2021-12-25",
    date_fin: null,
    statut: 0,
    jourD: "",
    moisD: "",
    anneeD: "",
    jourF: "",
    moisF: "",
    anneeF: "",
    allFarmers: false,
  });

  const [state, setState] = useState(null);

  const dispatch = useDispatch();
  const { addCampainData, addCampainError, loading } = useSelector(
    (properties) => properties.campain
  );
  useEffect(() => {
    if (addCampainData) {
      window.location.href = "/list-farmers?success=true";
    }
  }, [addCampainData]);
  useEffect(() => {
    if (addCampainError) {
      if (addCampainError.code == "token_not_valid") {
        window.location.href = "/";
      } else {
        
        let errors = [];
        for (let err in addCampainError) {
          let message =
            "[" + err.replaceAll("_", " ") + "]: " + addCampainError[err][0];
          
          if (!state || !state.geometry) {
            errors = ["[Delimitation champ]: Vous devez delimiter le champ"];
            break;
          }
          if (!errors.includes(message)) {
            errors.push(message);
          }
        }

        setMyErrors(
          errors.map((e, i) => (
            <li style={{ margin: 0, color: "red" }} key={i.toString()}>
              {e}
            </li>
          ))
        );
        window.scrollTo(0, 0);
      }
    }
  }, [addCampainError]);

  useEffect(() => {
    if (state) {
      let st = { ...state };
      st.properties = properties;
      setState(st);
    }
  }, [properties]);

  const submit = () => {
    let state_cp = Object.assign({}, state);
    let properties_cp = Object.assign({}, state_cp.properties);
    state_cp.properties = properties_cp;
    dispatch(addCampain(state_cp));
  };

  const updateItem = (key, value) => {
    const myproperties = { ...properties };
    myproperties[key] = value;
    setProperties(myproperties);
  };

  useEffect(() => {
    updateItem(
      "date_debut",
      `${properties.anneeD}-${properties.moisD}-${properties.jourD}`
    );
  }, [properties.jourD, properties.moisD, properties.anneeD]);


  useEffect(() => {
    updateItem(
      "date_fin",
      `${properties.anneeF}-${properties.moisF}-${properties.jourF}`
    );
  }, [properties.jourF, properties.moisF, properties.anneeF]);


  useEffect(() => {
    if (location.state && (location.state.geometry || location.state.properties)) {
      setState(location.state.geometry);
      setProperties(location.state.properties);
    }
    if(location.state?.farmer && !properties.farmer ){
      setProperties({...properties,farmer: location.state?.farmer})
    }
  }, [location]);

const handleList = (listname, item) =>{
  const properties_cp = {...properties}
  let list = properties_cp[listname]
  if(list.includes(item)){
    list = list.filter(l => l !== item)
  }else{
    list.push(item)
  }
  properties_cp[listname] = list
  setProperties(properties_cp)
}


  return (
    <SideBars>
      <h1 style={{ fontSize: 18 }}>{getTitle(type)}</h1>
      <Form>
        {myErrors && (
          <ul style={{ diplay: myErrors ? "" : "none" }}>{myErrors}</ul>
        )}
        <Input
          value={properties.plante}
          name="plante"
          onChange={({ target }) => updateItem("plante", target.value)}
          label="Plante"
          sm={10}
          placeholder="Nom de la plante"
        />
        <Input
          value={properties.nombre_ouvriers}
          onChange={({ target }) => updateItem("nombre_ouvriers", target.value)}
          label="Nombre d'ouvriers"
          mt={20}
          sm={5}
          type="number"
          placeholder=""
        />
        <Select
          value={properties.source_eau}
          onChange={({ target }) => updateItem("source_eau", target.value)}
          items={sources_eau}
          label="Source d'eau"
          sm={5}
        />
        <Input
          value={properties.rendement}
          onChange={({ target }) => updateItem("rendement", target.value)}
          label="Rendement à l'hectare"
          sm={10}
          placeholder="Rendement de la récolte"
        />
        <Select
          value={properties.amendement}
          items={amendements}
          sm={5}
          label="Amendement"
          onChange={({ target }) =>
            updateItem("amendement", target.value)
          }
        />
        <Grid sm={5}/>
        <FormTextSeparator text="Type de semence" />
        <CustomRadio
          value={1}
          checked={properties.est_semence_certifie === true}
          onChange={({ target }) => updateItem("est_semence_certifie", true)}
          label="Certifiée"
          sm={3}
        />
        <CustomRadio
          value={2}
          checked={properties.est_semence_certifie === false}
          onChange={({ target }) => updateItem("est_semence_certifie", false)}
          label="Non Certifiée"
          sm={3}
        />
        <Grid sm={4}/>

        <Grid item sm={10} style={{ marginTop: 10 }}>
          <NavLink
            to="/map"
            state={{properties, geometry: state ? state.geometry : null, sourcePage: "/add-campain"}}
            style={{ textDecoration: "none" }}
          >
            <Button fullWidth variant="outlined">
              {location.state
                ? "Modifier délimitation zone"
                : "Délimiter zone à cultiver"}
            </Button>
          </NavLink>
        </Grid>
        
        <FormTextSeparator text="Type d'engrais utilisé" />
        <CustomCheckbox
          checked={properties.type_engrais === "AZOTE"}
          onChange={({ target }) =>
            updateItem("type_engrais", "AZOTE")
          }
          label="Engrais azoté"
        />
        <CustomCheckbox
          checked={properties.type_engrais ==="PHOSPHATE"}
          onChange={({ target }) => updateItem("type_engrais", "PHOSPHATE")}
          label="Engrais phosphaté"
        />
        <CustomCheckbox
          checked={properties.type_engrais === "POTASSIQUE"}
          onChange={({ target }) =>
            updateItem("type_engrais", "POTASSIQUE")
          }
          label="Engrais potassique"
        />
        <CustomCheckbox
          checked={properties.type_engrais  === "NPK"}
          label="NPK"
          onChange={({ target }) =>
            updateItem("type_engrais", "NPK")
          }
        />
        <FormTextSeparator text="Date de début" />
        <Select
          value={properties.jourD}
          items={range(1, 32)}
          sm={3}
          label="Jour"
          onChange={({ target }) => updateItem("jourD", target.value)}
        />
        <Select
          value={properties.moisD}
          items={range(1, 13)}
          sm={3}
          label="Mois"
          onChange={({ target }) => updateItem("moisD", target.value)}
        />
        <Select
          value={properties.anneeD}
          items={range(1950, 2023)}
          sm={3}
          label="Année"
          onChange={({ target }) => updateItem("anneeD", target.value)}
        />

      <FormTextSeparator text="Date de fin" />
        <Select
          value={properties.jourF}
          items={range(1, 32)}
          sm={3}
          label="Jour"
          onChange={({ target }) => updateItem("jourF", target.value)}
        />
        <Select
          value={properties.moisF}
          items={range(1, 13)}
          sm={3}
          label="Mois"
          onChange={({ target }) => updateItem("moisF", target.value)}
        />
        <Select
          value={properties.anneeF}
          items={range(1950, 2023)}
          sm={3}
          label="Année"
          onChange={({ target }) => updateItem("anneeF", target.value)}
        />

      <FormTextSeparator text="Statut" />
      <CustomRadio
          value={0}
          checked={properties.statut === 0}
          onChange={({ target }) => updateItem("statut", 0)}
          label="Non débuté"
          sm={3}
        />
        <CustomRadio
          value={1}
          checked={properties.statut === 1}
          onChange={({ target }) => updateItem("statut", 1)}
          label="En cours"
          sm={3}
        />
        <CustomRadio
          value={2}
          checked={properties.statut === 2}
          onChange={({ target }) => updateItem("statut", 2)}
          label="Terminé"
          sm={3}
        />
        <Grid sm={1} />
      <FormTextSeparator text="Matériels légers" />
      <Grid sm={10} >
      <div style={{display: "flex", flexDirection: "row",flexWrap: "wrap"}}>
        {petit_mat.map((item,index) => <RoundedItem key={index.toString()} selected={properties.petits_materiels.includes(item.value)}  title={item.name} action={() => handleList("petits_materiels",item.value)} />)}

      </div>
      </Grid>  
      

      <FormTextSeparator text="Matériels lourds" />
      <Grid sm={10} >
      <div style={{display: "flex", flexDirection: "row",flexWrap: "wrap"}}>
        {mat_lourd.map((item,index) => <RoundedItem key={index.toString()} selected={properties.materiels_lourds.includes(item.value)}  title={item.name} action={() => handleList("materiels_lourds",item.value)} />)}

      </div>
      </Grid> 

        <LargeButton action={submit} title={getBtnText(type)} sm={12} />
      </Form>
    </SideBars>
  );
};

export default Campain;
