import { Grid } from "@mui/material";
import React from "react";

export default function Form({ children }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <form
        style={{
          width: "80%",
          backgroundColor: "#F0F0F0",
          borderRadius: 10,
          padding: 10,
          
        }}
      >
        <Grid container style={{justifyContent: "center",
          display: "flex",
          flexDirection: "row"
         }}>
        {children}
        </Grid>
      </form>
    </div>
  );
}
