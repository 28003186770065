import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import React from "react";

export default function SelectInput({items=[], label,sm,onChange, value="title"}) {
  return (
    <Grid item sm={sm} >
      <FormControl fullWidth>
      <Select inputProps={{ 'aria-label': 'Without label' }} displayEmpty value={value} onChange={onChange} style={styles.input} defaultValue="title" labelId="select-label" id="select" >
        <MenuItem  value={""}>{label}</MenuItem>
          {items.map((item, i) => <MenuItem key={i.toString()} value={item.value}>{item.name}</MenuItem>)}
      </Select>
      </FormControl>
      </Grid>
  );
}
const styles = {
  input: {
    marginTop: 20,
    backgroundColor: "white"
  },
};