import { Grid } from '@mui/material'
import React from 'react'
import * as icons from "react-icons/md"
import { NavLink } from 'react-router-dom'

function IconButton({isActive=false, icon, title,link="/",state = null }) {
    let MyIcon = icons[icon]
    return (
        <NavLink state={state} to={link} style={{textDecoration: "none"}}>
        <Grid container style={{height: 45, width:  180, backgroundColor: isActive ? "#55AE58": "", borderRadius: 10, marginTop: 20}}>
            <Grid item sm={3} style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <MyIcon size={20} color={isActive ? "#FFFFFF" : "#7A7A7A"}/>
            </Grid>
            <Grid item sm={8} style={{display: "flex", alignItems: "center",  color: isActive ? "#FFFFFF" : "#7A7A7A"}}>
                <p style={{margin: 0, fontWeight: "bold", fontSize: 12}}>{title}</p>
            </Grid>
            <Grid item sm={1} /> 
        </Grid>
        </NavLink>
    )
}

export default IconButton
