import { Divider, Grid } from '@mui/material'
import React from 'react'

export default function ItemInfo({title, value, size,show, children}) {
    return (
        <>
          <Grid sm={0.01} style={{display: show ? "" : "none"}}>
                <Divider orientation="vertical"/>
              </Grid>
              <Grid sm={size} style={{padding: 5}}>
                <p style={{color: "#757575", margin: 0}}>{title}</p>
                <p style={{color: "#55AE58", margin: 0, fontWeight: "bold"}}>{value}</p>
                {children}
              </Grid>
              

        </>
    )
}
