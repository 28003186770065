import React from 'react'
import SideBars from '../components/SideBars'

function ListeComptes() {
    return (
        <SideBars>
            <h1>Liste comptes</h1>
        </SideBars>
    )
}

export default ListeComptes
