import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ADD_MEMBER, DELETE_MEMBER, GET_MEMBERS } from '../endpoints'

const initialState = {
  loading: false,
  addMemberData: null,
  addMemberError: null,
  members: [],
  getMembersError: null,
  deleteMember: null,
  deleteMemberData: null,
  deleteMemberError: null
}

export const getMembers = createAsyncThunk(
  'member/get',
  async (data,{rejectWithValue}) => {
    try {
      const res = await axios.get(GET_MEMBERS+"?profil="+data.memberType,{
          headers:{
              Authorization: "Bearer "+ JSON.parse(localStorage.getItem('data')).access
          }
      })
  return res.data
      
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error)
    }
  })

 export const addMember = createAsyncThunk(
    'member/add',
    async (data,{rejectWithValue}) => {
      try {
        const res = await axios.post(ADD_MEMBER,data,{
            headers:{
                Authorization: "Bearer "+ JSON.parse(localStorage.getItem('data')).access
            }
        })
    return res.data
        
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error)
      }
  })

  export const deleteMember = createAsyncThunk(
    'member/delete',
    async (data,{rejectWithValue}) => {
      try {
        const res = await axios.delete(DELETE_MEMBER+data.memberId,{
            headers:{
                Authorization: "Bearer "+ JSON.parse(localStorage.getItem('data')).access
            }
        })
    return res.data
        
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error)
      }
  })

  const getAgencies = createAsyncThunk(
    'member/getAgencies',
    async (thunkAPI) => {
      const res = await axios.post('https://jsonplaceholder.typicode.com/posts',{}).then(
      (data) => data.json()
    )
    return res
  })

  const getOneAgency = createAsyncThunk(
    'member/getOneAgency',
    async (thunkAPI) => {
      const res = await axios.post('https://jsonplaceholder.typicode.com/posts',{}).then(
      (data) => data.json()
    )
    return res
  })

  const updateAgency = createAsyncThunk(
    'member/updateAgency',
    async (thunkAPI) => {
      const res = await axios.post('https://jsonplaceholder.typicode.com/posts',{}).then(
      (data) => data.json()
    )
    return res
  })

  const deleteAgency = createAsyncThunk(
    'member/deleteAgency',
    async (thunkAPI) => {
      const res = await axios.post('https://jsonplaceholder.typicode.com/posts',{}).then(
      (data) => data.json()
    )
    return res
  })

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    
  },
  extraReducers: {
      [getMembers.pending]: (state) => {
        state.loading = true
        state.members = []
        state.getMembersError = null
      },
      [getMembers.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.members = payload

      },
      [getMembers.rejected]: (state,{payload}) => {
        state.loading = false
        state.getMembersError = payload

      },
      [getAgencies.pending]: (state) => {
        state.loading = true
      },
      [getAgencies.fulfilled]: (state, {payload}) => {
        state.loading = false
      },
      [getAgencies.rejected]: (state) => {
        state.loading = false
      },
      [getOneAgency.pending]: (state) => {
        state.loading = true
      },
      [getOneAgency.fulfilled]: (state, {payload}) => {
        state.loading = false
      },
      [getOneAgency.rejected]: (state) => {
        state.loading = false
      },
      [updateAgency.pending]: (state) => {
        state.loading = true
      },
      [updateAgency.fulfilled]: (state, {payload}) => {
        state.loading = false
      },
      [updateAgency.rejected]: (state) => {
        state.loading = false
      },
      [deleteAgency.pending]: (state) => {
        state.loading = true
      },
      [deleteAgency.fulfilled]: (state, {payload}) => {
        state.loading = false
      },
      [deleteAgency.rejected]: (state) => {
        state.loading = false
      },
      [addMember.pending]: (state) => {
        state.loading = true
        state.addMemberData = null
        state.addMemberError = null
      },
      [addMember.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.addMemberData = payload
      },
      [addMember.rejected]: (state,{payload}) => {
        state.loading = false
        state.addMemberError = payload

      },
      [deleteMember.pending]: (state) => {
        state.loading = true
        state.deleteMemberData = null
        state.deleteMemberError = null
      },
      [deleteMember.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.deleteMemberData = payload
      },
      [deleteMember.rejected]: (state,{payload}) => {
        state.loading = false
        state.deleteMemberError = payload

      },
  }
})

// Action creators are generated for each case reducer function
export const memberReducer = memberSlice.reducer