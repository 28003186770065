import { Grid } from "@mui/material";
import React from "react";

export default function RoundedItem({ selected = false, action=() => {}, title}) {
  return (

    <div
      onClick={action}
      style={{
          padding: 10,
          borderRadius: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: selected ? "#F8FFF1" : "#E5E5E5",
          border: selected ? "solid #55AE58 .5px" : "solid #E5E5E5 .5px",
          cursor: "pointer",
           marginLeft: 10, marginTop: 10
        }}
        >
      <p style={{ margin: 0, color: selected ? "#55AE58" : "#5F5F5F" }}>
        {title}
      </p>
    </div>
  );
}
