
import React from "react";
import Form from "../components/Form";
import SideBars from "../components/SideBars";
import Input from "../components/Input";
import LargeButton from "../components/LargeButton";
import Select from "../components/Select";
import FormTextSeparator from "../components/FormTextSeparator";

const agencies = [
  {
    name: "Agence Kheuch",
    value: "ak",
  },
  {
    name: "Agence Modou",
    value: "am",
  },
  {
    name: "Agence Baobab",
    value: "ab",
  },
  {
    name: "Agence Crocodile",
    value: "ac",
  },
];

const getTitle = (type) => {
  switch (type) {
    case "add":
      return "Ajouter une nouvelle agence"
    case "update":
      return "Modifier les informations"
    default:
      break;
  }
}

const getBtnText = (type) => {
  switch (type) {
    case "add":
      return "Ajouter l'agence"
    case "update":
      return "Mettre à jour les informations"
    default:
      break;
  }
}

function Agency({type="add"}) {
  return (
    <SideBars>
      <h1 style={{ fontSize: 18 }}>{getTitle(type)}</h1>
      <Form>
        <Input label="Nom" sm={10} placeholder="Entrez le nom de l'agence" />
        <Select items={agencies} sm={10} label="Agence nationale" />
        <Select items={agencies} label="Pays" sm={5} />
        <Select items={agencies} sm={5} label="Ville" />
        <Input label="Adresse" sm={10} placeholder="Entrez l'adresse de l'agence" />
        <Input label="Mail de l'agence" sm={10} placeholder="Entrez le mail de l'agence" />
        <FormTextSeparator text="Personne de contact"/>
        <Input label="Nom" sm={10} placeholder="Nom de la personne" />
        <Input label="Prénom" sm={10} placeholder="Prénom de la personne" />
        <Input label="Adresse mail" sm={10} placeholder="Entrez le mail de la personne" />

        <LargeButton title={getBtnText(type)} sm={12} />
      </Form>
    </SideBars>
  );
}

export default Agency;
