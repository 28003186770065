import React, { useEffect, useState } from "react";
import Form from "../components/Form";
import SideBars from "../components/SideBars";
import Input from "../components/Input";
import LargeButton from "../components/LargeButton";
import Select from "../components/Select";
import FormTextSeparator from "../components/FormTextSeparator";
import { Grid, Button } from "@mui/material";
import CustomCheckbox from "../components/CustomCheckbox";
import CustomRadio from "../components/CustomRadio";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFarmer } from "../slices/farmerSlice";

const sexes = [
  {
    name: "Homme",
    value: "M",
  },
  {
    name: "Femme",
    value: "F",
  },
];

const villes = [
  {
    name: "Dakar",
    value: "Dakar",
  },
  {
    name: "Thies",
    value: "Thies",
  },
];

const langues = [
  {
    name: "Wolof",
    value: "WOLOF",
  },
  {
    name: "Serere",
    value: "SERERE",
  },
  {
    name: "Diola",
    value: "DIOLA",
  },
  {
    name: "Pulaar",
    value: "PULAAR",
  },
  {
    name: "Francais",
    value: "FRANCAIS",
  },
  {
    name: "Anglais",
    value: "ANGLAIS",
  },
];

const situ_mat = [
  {
    name: "Celibataire",
    value: "CELIBATAIRE",
  },
  {
    name: "Marié",
    value: "MARIE",
  },
  {
    name: "Divorcé",
    value: "DIVORCE",
  },
  {
    name: "Veuf",
    value: "VEUF",
  },
];

const agencies = [
  {
    name: "Agence Kheuch",
    value: "ak",
  },
  {
    name: "Agence Modou",
    value: "am",
  },
  {
    name: "Agence Baobab",
    value: "ab",
  },
  {
    name: "Agence Crocodile",
    value: "ac",
  },
];
const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output.map((i) => {
    return { name: i.toString(), value: i.toString() };
  });
};

const getTitle = (type) => {
  switch (type) {
    case "add":
      return "Remplir les informations";
    case "update":
      return "Modifier les informations";
    default:
      break;
  }
};

const getBtnText = (type) => {
  switch (type) {
    case "add":
      return "Ajouter l'agriculteur";
    case "update":
      return "Mettre à jour les informations";
    default:
      break;
  }
};

const Farmer = ({ type = "add" }) => {
  const [mobileMoney, setMobileMoney] = useState("");
  const [myErrors, setMyErrors] = useState("");

  const location = useLocation();

  const [properties, setProperties] = useState({
    radios: ["rfm"],
    operateur_mobile_money: null,
    prenom: null,
    nom: null,
    sexe: "",
    situation_matrimoniale: "",
    date_naissance: null,
    numero_mobile: null,
    langue_parlee: null,
    pays: "Senegal",
    ville: null,
    est_malentendant: true,
    est_illettre: true,
    peut_composer_un_numero: false,
    dispose_de_mobile_money: false,
    numero_mobile_money: null,
    jour: "",
    mois: "",
    annee: "",
    region: "Dakar",
    autres_attributs: {
      surnom: "kheuch",
    },
    allFarmers: false,
  });

  const [state, setState] = useState(null);

  const dispatch = useDispatch();
  const { farmerData, farmerError, loading } = useSelector(
    (properties) => properties.farmer
  );
  useEffect(() => {
    if (farmerData) {
      window.location.href = "/list-farmers?success=true";
    }
  }, [farmerData]);
  useEffect(() => {
    if (farmerError) {
      if (farmerError.code == "token_not_valid") {
        window.location.href = "/";
      } else {
        let errors = [];
        for (let err in farmerError) {
          let message =
            "[" + err.replaceAll("_", " ") + "]: " + farmerError[err][0];
          if (err === "localisation_champ") {
            errors = ["[Delimitation champ]: Vous devez delimiter le champ"];
            break;
          }
          if (!errors.includes(message)) {
            errors.push(message);
          }
        }

        setMyErrors(
          errors.map((e, i) => (
            <li style={{ margin: 0, color: "red" }} key={i.toString()}>
              {e}
            </li>
          ))
        );
        window.scrollTo(0, 0);
      }
    }
  }, [farmerError]);

  useEffect(() => {
    if (state) {
      let st = { ...state };
      st.properties = properties;
      setState(st);
    }
  }, [properties]);

  const submit = () => {
    let state_cp = Object.assign({}, state);
    let properties_cp = Object.assign({}, state_cp.properties);
    state_cp.properties = properties_cp;
    if (state_cp.properties) {
      state_cp.properties.numero_mobile =
        "+221" + state_cp.properties.numero_mobile;
      state_cp.properties.numero_mobile_money =
        "+221" + state_cp.properties.numero_mobile_money;
    }
    dispatch(addFarmer(state_cp));
  };

  const updateItem = (key, value) => {
    const myproperties = { ...properties };
    myproperties[key] = value;
    setProperties(myproperties);
  };

  useEffect(() => {
    updateItem(
      "date_naissance",
      `${properties.annee}-${properties.mois}-${properties.jour}`
    );
  }, [properties.jour, properties.mois, properties.annee]);

  useEffect(() => {
    updateItem("operateur_mobile_money", mobileMoney);
  }, [mobileMoney]);

  useEffect(() => {
    if (location.state) {
      setState(location.state.geometry);
      setProperties(location.state.properties);
    }
  }, [location]);

  return (
    <SideBars>
      <h1 style={{ fontSize: 18 }}>{getTitle(type)}</h1>
      <Form>
        {myErrors && (
          <ul style={{ diplay: myErrors ? "" : "none" }}>{myErrors}</ul>
        )}
        <Input
          value={properties.nom}
          name="nom"
          onChange={({ target }) => updateItem("nom", target.value)}
          label="Nom"
          sm={10}
          placeholder="Nom de la personne"
        />
        <Input
          value={properties.prenom}
          onChange={({ target }) => updateItem("prenom", target.value)}
          label="Prénom"
          sm={10}
          placeholder="Prénom de l'agriculteur"
        />
        <Select
          value={properties.sexe}
          onChange={({ target }) => updateItem("sexe", target.value)}
          items={sexes}
          label="Sexe"
          sm={5}
        />
        <Select
          value={properties.situation_matrimoniale}
          items={situ_mat}
          sm={5}
          label="Situation matrimoniale"
          onChange={({ target }) =>
            updateItem("situation_matrimoniale", target.value)
          }
        />

        <FormTextSeparator text="Date de naissance" />
        <Select
          value={properties.jour}
          items={range(1, 32)}
          sm={3}
          label="Jour"
          onChange={({ target }) => updateItem("jour", target.value)}
        />
        <Select
          value={properties.mois}
          items={range(1, 13)}
          sm={3}
          label="Mois"
          onChange={({ target }) => updateItem("mois", target.value)}
        />
        <Select
          value={properties.annee}
          items={range(1950, 2023)}
          sm={3}
          label="Année"
          onChange={({ target }) => updateItem("annee", target.value)}
        />
        <Grid item sm={1} />
        <FormTextSeparator text="Numéro de téléphone" />
        <Input
          value={properties.numero_mobile}
          name="numero_mobile"
          start={"+221"}
          onChange={({ target }) => updateItem("numero_mobile", target.value)}
          label="Numéro de téléphone"
          sm={10}
          placeholder="777777777"
        />
        <Select
          value={
            properties.langue_parlee ? properties.langue_parlee[0] : ""
          }
          items={langues}
          onChange={({ target }) => updateItem("langue_parlee", [target.value])}
          label="Langue parlée"
          sm={5}
        />
        <Select
          value={properties.ville ? properties.ville : ""}
          items={villes}
          onChange={({ target }) => updateItem("ville", target.value)}
          sm={5}
          label="Ville"
        />
        {/* <Input name="" label="Adresse" sm={10} placeholder="Adresse de l'agriculteur" /> */}
        <CustomCheckbox
          checked={!properties.est_malentendant}
          onChange={({ target }) =>
            updateItem("est_malentendant", !target.checked)
          }
          label="habilité à entendre"
        />
        <CustomCheckbox
          checked={!properties.est_illettre}
          onChange={({ target }) => updateItem("est_illettre", !target.checked)}
          label="habilité à lire"
        />
        <CustomCheckbox
          checked={properties.peut_composer_un_numero}
          onChange={({ target }) =>
            updateItem("peut_composer_un_numero", target.checked)
          }
          label="habilité à composer un numéro de téléphone"
        />
        <CustomCheckbox
          checked={properties.dispose_de_mobile_money}
          label="Mobile money"
          onChange={({ target }) =>
            updateItem("dispose_de_mobile_money", target.checked)
          }
        />

        <CustomRadio
          value={1}
          checked={properties.operateur_mobile_money === 1}
          onChange={({ target }) => updateItem("operateur_mobile_money", 1)}
          label="Wave"
          sm={3}
        />
        <CustomRadio
          value={2}
          checked={properties.operateur_mobile_money === 2}
          onChange={({ target }) => updateItem("operateur_mobile_money", 2)}
          label="Orange money"
          sm={4}
        />
        <CustomRadio
          value={3}
          checked={properties.operateur_mobile_money === 3}
          onChange={({ target }) => updateItem("operateur_mobile_money", 3)}
          label="Free money"
          sm={3}
        />
        <Input
          value={properties.numero_mobile_money}
          onChange={({ target }) =>
            updateItem("numero_mobile_money", target.value)
          }
          name=""
          start={"+221"}
          label="Numéro de téléphone"
          sm={10}
          placeholder="777777777"
        />
        <Grid item sm={10} style={{ marginTop: 10 }}>
          <NavLink
            to="/map"
            state={{properties, geometry: state ? state.geometry : null, sourcePage: "/add-farmer"}}
            style={{ textDecoration: "none" }}
          >
            <Button fullWidth variant="outlined">
              {location.state
                ? "Modifier délimitation champ"
                : "Délimiter champ"}
            </Button>
          </NavLink>
        </Grid>

        <LargeButton action={submit} title={getBtnText(type)} sm={12} />
      </Form>
    </SideBars>
  );
};

export default Farmer;
