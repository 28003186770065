const API_URL = "https://api.tolbico.com/api"

//Auth
export const LOGIN = API_URL+"/token/";

//Agences
export const ADD_FARMER = API_URL+"/agences/"+(JSON.parse(localStorage.getItem('data'))?.agence_id || 0)+"/agriculteurs/";
export const DELETE_FARMER = API_URL+"/agences/"+(JSON.parse(localStorage.getItem('data'))?.agence_id || 0)+"/agriculteurs/";
export const ADD_MEMBER = API_URL+"/agences/"+(JSON.parse(localStorage.getItem('data'))?.agence_id || 0)+"/membres/";
export const GET_MEMBERS = API_URL+"/agences/"+(JSON.parse(localStorage.getItem('data'))?.agence_id || 0)+"/membres";
export const DELETE_MEMBER = API_URL+"/agences/"+(JSON.parse(localStorage.getItem('data'))?.agence_id || 0)+"/membres/";

export const CAMPAIN = API_URL+"/agences/"+(JSON.parse(localStorage.getItem('data'))?.agence_id || 0)+"/agriculteurs/";
