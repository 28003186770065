import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox, IconButton, Switch } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { BiTrash } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import { agencyColumns, farmerColumns, groupmentColumns } from "../utils/tableColumns";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { deleteFarmer } from "../slices/farmerSlice";


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



const agencyRows = [
  createData("Frozen yoghurt", "sss", 6.0, 24),
  createData("Ice cream sandwich", "ee", 9.0, 37),
  createData("Eclair", "ddd", 16.0, 24),
  createData("Cupcake", "fff", 3.7, 67),
  createData("Gingerbread", "sssq", 16.0, 49),
];



const groupmentRows = [
  createData("Frozen yoghurt", "sss", 6.0),
  createData("Ice cream sandwich", "ee", 9.0),
  createData("Eclair", "ddd", 16.0),
  createData("Cupcake", "fff", 3.7),
  createData("Gingerbread", "sssq", 16.0),
];

const getColumns = (type) => {
  switch (type) {
    case "agence":
      return agencyColumns;
    case "agriculteur":
      return farmerColumns;
    case "groupement":
      return groupmentColumns;
    default:
      return agencyColumns;
  }
};



export default function BasicTable({type,data=[]}) {
  const TYPE = type;
  const [allChecked, setAllChecked] = React.useState(false);
  let farmerRows = null
   farmerRows = data.map(d=> createData(d.properties?.prenom+" "+d.properties?.nom, d.properties?.numero_mobile,d))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { deleteFarmerData, deleteFarmerError, loading } = useSelector((state) => state.farmer)
  React.useEffect(() =>{
    if(deleteFarmerData){
      window.location.reload()
    }
  },[deleteFarmerData])
  React.useEffect(() =>{
    if(deleteFarmerError){
      if(deleteFarmerError.code == "token_not_valid"){
        window.location.href = "/"

      }else{
        alert("Une erreur est survenue. Verifiez votre connexion et ressayez")
      }
    }
  },[deleteFarmerError])


  const getRows = (type) => {
    switch (type) {
      case "agence":
        return agencyRows;
      case "agriculteur":
        return farmerRows;
      case "groupement":
        return groupmentRows;
      default:
        return agencyRows;
    }
  };


  const handleDelete=(data) => {
    dispatch(deleteFarmer(data))
  }

  const getActionButtons = (type,user) => {
    switch (type) {
      case "agence":
        return <Switch defaultChecked />;
      case "agriculteur":
        return (
          <>
          <IconButton onClick={() => {
              navigate("/add-campain",{
                state: {
                  farmer: user
                }
              })
            }}>
              <img src="/assets/images/add_campain.png" alt="campain"/>
            </IconButton>
            <IconButton onClick={() => {
              navigate("/map",{
                state: {
                  user 
                }
              })
            }}>
              <MdLocationOn color="FF4141" />
            </IconButton>
            <IconButton>
              <IoIosCall size="18" color="white" style={{backgroundColor: "#55AE58", borderRadius: 100,padding: 2}} />
            </IconButton>
          </>
        );
      case "groupement":
          return
      default:
        return <Switch defaultChecked />;;
    }
  };

  return (
    <TableContainer style={{ marginTop: 20 }} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={allChecked}
                onChange={({ target }) => setAllChecked(target.checked)}
              />
            </TableCell>
            {getColumns(TYPE).map((name, i) => (
              <TableCell key={i.toString()}>{name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows(TYPE).map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Checkbox checked={allChecked} />
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.calories}</TableCell>
              {TYPE !== "agriculteur" && <TableCell>{row.fat}</TableCell> }
              <TableCell>
                {/* <Switch defaultChecked /> */}
                {getActionButtons(TYPE,row.fat)}
                <IconButton>
                  <FiEdit color="#F0B903" />
                </IconButton>
                <IconButton>
                  <BiTrash color="#FF4141" onClick={() => handleDelete(row.fat)} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
