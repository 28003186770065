import { Button, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { IoIosCall } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { deleteMember } from '../slices/memberSlice';

export default function MemberListItem({memberData={}}) {
    const dispatch = useDispatch();
  const { deleteMemberData, deleteMemberErr, loading } = useSelector(
    (properties) => properties.farmer
  );
  useEffect(() => {
    if (deleteMemberData) {
      window.location.href = "/list-farmers";
    }
  }, [deleteMemberData]);
  useEffect(() => {
    if (deleteMemberErr) {
      alert("Une erreur est survenue !");
    }
  }, [deleteMemberErr]);


  const remove = () => {
    dispatch(deleteMember({memberId: memberData.id}))
  }

    return (
        <Grid item sm={6} style={{marginTop: 10}}> 
            <div style={{ width: "80%", height: "auto", padding: 10, flexDirection: "column",display: "flex", justifyContent: "center",alignItems: "center" ,boxShadow: "0px 16px 28px rgba(129, 129, 129, 0.1), 0px 28px 50px rgba(129, 129, 129, 0.05)"}}>
                <img src="/assets/images/user.png" alt="user" width="100" /> 
                <div style={{backgroundColor: "#55AE58", borderRadius: 10, padding: 5, color: "white", marginTop: 5}}>
                <p style={{margin:0}}>{memberData.prenom} {memberData.nom}</p>
                </div>
                <IoIosCall color='55AE58' />
                <p style={{margin: 0, fontSize: 12}}>{memberData.numero_mobile || "-"}</p>
                <MdEmail color="55AE58" />
                <p style={{margin: 0, fontSize: 12}}>{memberData.email}</p>
                <div  style={{display: "flex", marginTop: 10, justifyContent: "space-between", width: "70%"}} >
                    <Button color="primary" size="small" children="Modifier" variant="outlined" />
                    <Button style={{backgroundColor: "red", color: "white"}} size="small" children="Supprimer" onClick={remove} variant="contained" />
                </div>
            </div>
        </Grid>
    )
}
