import React, { useEffect, useState } from "react";
import Form from "../components/Form";
import MemberItem from "../components/MemberItem";
import SideBars from "../components/SideBars";
import { members } from "../utils/members";
import Input from "../components/Input";
import FormTextSeparator from "../components/FormTextSeparator";
import LargeButton from "../components/LargeButton";
import { useDispatch, useSelector } from "react-redux";
import { addMember } from "../slices/memberSlice";

const getTitle = (type) => {
  switch (type) {
    case "add":
      return "Ajouter un nouveau membre";
    case "update":
      return "Modifier les informations";
    default:
      break;
  }
};

const getBtnText = (type) => {
  switch (type) {
    case "add":
      return "Ajouter le membre";
    case "update":
      return "Mettre à jour les informations";
    default:
      break;
  }
};

export default function Member({ type = "add" }) {
  const [state, setState] = useState({
    prenom: "",
    nom: "",
    email: "",
    numero_mobile: "",
    profil: "",
  });

  const dispatch = useDispatch();
  const { addMemberData, addMemberError, loading } = useSelector(
    (properties) => properties.member
  );
  useEffect(() => {
    if (addMemberData) {
      window.location.href = "/list-members";
    }
  }, [addMemberData]);
  useEffect(() => {
    if (addMemberError) {
      alert("Remplissez tous les champs !");
    }
  }, [addMemberError]);

  const updateItem = (key, value) => {
    const myState = { ...state };
    myState[key] = value;
    setState(myState);
  };

  const submit = () => {
    const newState = { ...state };
    newState.numero_mobile = "+221" + newState.numero_mobile;
    console.log(JSON.stringify(newState));
    dispatch(addMember(newState));
  };
  return (
    <SideBars>
      <h1 style={{ fontSize: 18 }}>{getTitle(type)}</h1>

      <Form>
        {members.map((m, i) => (
          <MemberItem
            isActive={m.type === state.profil}
            onClick={() => updateItem("profil", m.type)}
            key={i.toString()}
            type="square"
            logoUrl={m.logoUrl}
            title={m.title}
          />
        ))}
        <Input
          value={state.nom}
          name="Nom"
          onChange={({ target }) => updateItem("nom", target.value)}
          label="Nom de la personne"
          sm={10}
          placeholder="Entrez le nom du membre"
        />
        <Input
          value={state.prenom}
          onChange={({ target }) => updateItem("prenom", target.value)}
          label="Prénom"
          sm={10}
          placeholder="Entrez le prenom du membre"
        />
        <FormTextSeparator text="Numéro de téléphone" />
        <Input
          value={state.numero_mobile}
          name="numero_mobile"
          start={"+221"}
          onChange={({ target }) => updateItem("numero_mobile", target.value)}
          label="Numéro de téléphone"
          sm={10}
          placeholder="777777777"
        />

        <Input
          value={state.email}
          name="email"
          onChange={({ target }) => updateItem("email", target.value)}
          label="Adresse Email"
          sm={10}
          placeholder="Entrez l'adresse mail du membre"
        />
        <LargeButton action={submit} title={getBtnText(type)} sm={12} />
      </Form>
    </SideBars>
  );
}
