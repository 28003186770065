import React from "react";
import { FaPlus } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import AddButton from "./AddButton";

const getBtnTitle = (type) => {
  switch (type) {
    case "agence":
      return "Nouvelle agence";
    case "agriculteur":
      return "Nouvel agriculteur";
    
    default:
      return ;
  }
}
const getAddLink = (type) => {
  switch (type) {
    case "agence":
      return "/add-agency";
    case "agriculteur":
      return "/add-farmer";
    
    default:
      return ;
  }
}


function TableFilters({type}) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Grid container>
        <Grid item sm={9}>
          <div
            style={{
              width: "100%",
              borderRadius: 10,
              backgroundColor: "#F0F0F0",
              height: 70,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                flexDirection: "row",
                justifyContent: "space-evenly",
                margin: 10
              }}
            >
              <TextField
                id="outlined-basic"
                style={{ ...styles.input,width: type === "agence" ? 300 : 250 }}
                placeholder="Rechercher"
                variant="outlined"
                InputProps={{
                  style: {
                    fontWeight: "bold",
                    height: 40,
                  },
                }}
              />
              <Select
                style={{ width: 130, height: 40, backgroundColor: "white", display: type === "agence" ? "none": "" }}
                value="0"
              >
                <MenuItem value={0}>Trier par</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              <Select
                style={{ width: type === "agence" ? 150 : 130, height: 40, backgroundColor: "white" }}
                value="0"
              >
                <MenuItem value={0}>Filtrer par</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </div>
          </div>
        </Grid>
        <Grid
          item
          sm={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NavLink to={getAddLink(type) || ""} style={{ textDecoration: "none" }}>
            <AddButton display={getBtnTitle(type)} title={getBtnTitle(type)} />
            
          </NavLink>
        </Grid>
      </Grid>
    </div>
  );
}
const styles = {
  input: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#9F9F9F",
    width: 250,
    backgroundColor: "white",
  },
};

export default TableFilters;
