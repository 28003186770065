import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React from "react";

export default function CustomCheckbox({
  onChange = () => {},
  checked=false,
  label,
  sm = 10,
}) {
  return (
    <Grid sm={sm} style={{}}>
      <FormControlLabel
        control={
          <Checkbox defaultChecked checked={checked} onChange={onChange} />
        }
        label={label}
      />
    </Grid>
  );
}
