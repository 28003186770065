import { Grid } from '@mui/material'
import React from 'react'

export default function FormTextSeparator({text,sm=10}) {
    return (
        <Grid item sm={sm} style={{}} >
            <p style={{fontWeight: "bold",}}>{text}</p>
        </Grid>
    )
}
