import Account from './pages/Account';
import Login from './pages/Login';
import {
  BrowserRouter ,
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Services from './pages/Services';
import ListeComptes from './pages/ListeComptes';
import List from './pages/List';
import Agency from './pages/Agency';
import Farmer from './pages/Farmer';
import MyMap from './pages/MyMap';
import Member from './pages/Member';
import Terms from './pages/Terms';
import Campain from './pages/Campain';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/map" element={<MyMap/>} />
        <Route path="/add-agency" element={<Agency type="add"/>} />
        <Route path="/update-agency" element={<Agency type="update"/>} />
        <Route path="/add-farmer" element={<Farmer type="add"/>} />
        <Route path="/add-campain" element={<Campain type="add"/>} />
        <Route path="/update-farmer" element={<Farmer type="update"/>} />
        <Route path="/add-member" element={<Member type="add"/>} />

        <Route path="/list-agency" element={<List type="agence"/>} />
        <Route path="/list-members/" >
          <Route path=":memberType" element={<List type="membres"/>} />
          <Route path="" element={<List type="membres"/>} />
        </Route>
        <Route path="/list-farmers" element={<List type="agriculteur" />} />
        <Route path="/groupments" element={<List type="groupement" />} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/comptes" element={<ListeComptes/>} />
        <Route path="/packs" element={<ListeComptes/>} />
        <Route path="/points-focaux" element={<ListeComptes/>} />
        <Route path="/acteurs-externes" element={<ListeComptes/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/" element={<Login/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
