import React from 'react'
import SideBars from '../components/SideBars'

function Dashboard() {
    return (
        <SideBars>
            <h1>Dashboard</h1>
        </SideBars>
    )
}

export default Dashboard
