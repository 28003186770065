import { Grid } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

export default function MemberItem({
  title,
  logoUrl,
  goTo = "/list-members",
  type = "rectangle",
  isActive,
  onClick= () => {}
}) {
  if (type === "square")
    return (
      <div style={{ display: "flex", flexDirection: "column", margin: 20, cursor: "pointer" }} onClick={onClick}>
        <div
          style={{
            width: 86,
            height: 86,
            border: "1px solid #55AE58",
            borderRadius: 5,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            backgroundColor: isActive ? "#55AE58" : "white"
          }}
        >
          <img alt="logo" src={logoUrl} width="80" />
        </div>
        <p style={{ margin: 0, fontSize: 8, textAlign: "center", color: isActive? "#55AE58": "black" }}>
          {title}
        </p>
      </div>
    );

  return (
    <Grid item sm={12} style={{ marginTop: 10 }}>
      <NavLink
        to={goTo}
        style={{
          width: "100%",
          textDecoration: "none",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "80%",
            backgroundColor: "#FCFCFC",
            display: "flex",
            justifyContent: "space-between",
            height: 130,
            boxShadow:
              "0px 16px 28px rgba(129, 129, 129, 0.1), 0px 28px 50px rgba(129, 129, 129, 0.05)",
            padding: 25,
            borderRadius: 15,
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              marginTop: 60,
              fontSize: 18,
              color: "black",
            }}
          >
            {title}
          </p>
          <img alt="logo" src={logoUrl} />
        </div>
      </NavLink>
    </Grid>
  );
}
