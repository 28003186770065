import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";
import BasicTable from "../components/BasicTable";
import MemberList from "../components/MemberList";
import SideBars from "../components/SideBars";

import TableFilters from "../components/TableFilters";
import { getFarmers } from "../slices/farmerSlice";
import { getMembers } from "../slices/memberSlice";
function List({ type = "agence" }) {
  const [membersData, setMembersData] = useState([])
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  const { farmers, getFarmersError, loading, } = useSelector((state) => state.farmer)
  const { members, getMembersError, loading : l, } = useSelector((state) => state.member)
  const { memberType } = useParams();
  const location = useLocation()
  useEffect(() => {
    
    switch (type) {
      case "agriculteur":
        dispatch(getFarmers())
        
        break;
        case "membres":
          if(memberType)
          dispatch(getMembers({memberType}))
          break;
      default:
        break;
    }

  },[])
  
  useEffect(() => {
    if(memberType)
    dispatch(getMembers({memberType}))
  },[memberType])

  useEffect(() =>{
    if(farmers){
      setData(farmers.features)
    }
  },[farmers])

  useEffect(() =>{
    if(members){
      setMembersData(members)
    }
  },[members])

  useEffect(() =>{
    if(getFarmersError){
      window.location.href="/"
    }
  },[getFarmersError])

  useEffect(() =>{
    if(getMembersError){
      window.location.href="/"


    }
  },[getMembersError])  
  if(type==="membres") 
  return(
    <SideBars>
     <MemberList data={members}/>
    </SideBars>
  )
    else
  return (
    <SideBars>
     {location.search.includes("success=true") && <Alert severity="success">Ajout réussi !</Alert>}
     <TableFilters type={type}/>
      <BasicTable  type={type} data={data} />
    </SideBars>
  );
}



export default List;
