import { Button, TextField, } from "@mui/material";
import { withStyles } from "@mui/styles";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LargeButton from "../components/LargeButton";
import { login } from "../slices/authSlice";

const muiStyles = theme => ({
    root: {
      "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
        borderColor: "red"
      }
    },
    disabled: {},
    focused: {},
    error: {},
    notchedOutline: {}
  });
function Login({classes}) {

  const [username,setUsername] = useState("")
  const [password,setPassword] = useState("")
  const dispatch = useDispatch()
  const { loginData, loginError, loading } = useSelector((state) => state.auth)
  useEffect(() =>{
    if(loginData){
      window.localStorage.setItem("data", JSON.stringify(loginData))
      window.location.href = "/list-farmers"
    }
  },[loginData])
  useEffect(() =>{
    if(loginError){
      alert("Identifiant ou mot de passe incorrect !")
    }
  },[loginError])

  const submit = () => {
    dispatch(login({username,password}))
  }
  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.logoContainer}>
          <img src="/assets/images/logo.png" alt="logo" />
        </div>
        <h1 style={{ fontSize: 25 }}>Connexion</h1>
        <TextField
          id="outlined-basic"
          style={{ ...styles.input, marginTop: 10 }}
          label="Adresse mail"
          placeholder="Saisissez votre adresse mail"
          variant="outlined"
          value={username}
          onChange={({target})=> setUsername(target.value)}
          fullWidth
          InputProps={{
            style:{
                fontWeight: "bold",
            
            },
            classes:classes
          }}
        />
        <TextField
          id="outlined-basic"
          style={styles.input}
          type={"password"}
          label="Mot de passe"
          placeholder="Saisissez votre mot de passe"
          variant="outlined"
          value={password}
          onChange={({target})=> setPassword(target.value)}
          fullWidth
          InputProps={{
            style:{
                fontWeight: "bold",
            },
            classes:{
                notchedOutline: classes.notchedOutline,
              focused: classes.focused
            }
            
          }}
        />
        <LargeButton fullWidth action={submit} title="Se connecter" />
        
        <p>
          {/* eslint-disable-next-line */}
          <a
            href="#"
            style={{ textDecoration: "none", color: "#55AE58", fontSize: 14 }}
          >
            Mot de passe oublié ?
          </a>
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
            color: "#7A7A7A",
            marginTop: 40,
          }}
        >
          © 2021 Tolbi | <span style={{cursor: "pointer",color: "#55AE58"}} onClick={() => window.open("/terms","_blank") }>Terms | Privacy</span>
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundImage: "url(/assets/images/loginbg.png)",
    height: "100vh",
    width: "100%",
    backgroundColor: "black",
    backgroundSize: "100% 100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    backgroundColor: "white",
    width: 370,
    borderRadius: 20,
    padding: 30,
    paddingLeft: 50,
    paddingRight: 50,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  input: {
    marginTop: 20,
    fontSize: 20,
    fontWeight: "bold",
    color: "#9F9F9F",
  },
};

  export default withStyles(muiStyles)(Login);
