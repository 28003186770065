import { Button, Grid } from '@mui/material'
import React from 'react'

export default function LargeButton({title,sm,fullWidth,action=()=> {}, style={}}) {
    return (
      <Grid item sm={sm}  style={{textAlign: "center"}}>
        <Button
          variant="contained"
          fullWidth={fullWidth}
          onClick={action}
          size="large"
          style={{
            color: "white",
            backgroundColor: "#55AE58",
            fontWeight: "bold",
            textTransform: "none",
            marginTop: 20,
            boxShadow: "none",
            height: 50,
            fontSize: 20,
            ...style
          }}
        >
          {title}
        </Button>
        </Grid>
    )
}
