import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { ADD_FARMER, DELETE_FARMER, LOGIN } from '../endpoints'

const initialState = {
  loading: false,
  farmerData: null,
  farmerError: null,
  deleteFarmerData: null,
  deleteFarmerError: null,
  farmers: [],
  getFarmersError: null

}


export const addFarmer = createAsyncThunk(
    'farmer/add',
    async (data,{rejectWithValue}) => {
      try {
          console.log(ADD_FARMER);
        const res = await axios.post("https://api.tolbico.com/api/agences/"+(JSON.parse(localStorage.getItem('data'))?.agence_id || 0)+"/agriculteurs/",data,{
            headers:{
                Authorization: "Bearer "+ JSON.parse(localStorage.getItem('data')).access
            }
        })
    return res.data
        
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error)
      }
  })

  export const deleteFarmer = createAsyncThunk(
    'farmer/delete',
    async (data,{rejectWithValue}) => {
      try {
        const res = await axios.delete(DELETE_FARMER+data.id,{
            headers:{
                Authorization: "Bearer "+ JSON.parse(localStorage.getItem('data')).access
            }
        })
    return res.data
        
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error)
      }
  })
  export const getFarmers = createAsyncThunk(
    'farmers/get',
    async (data,{rejectWithValue}) => {
      try {
        const res = await axios.get("https://api.tolbico.com/api/agences/"+JSON.parse(localStorage.getItem('data')).agence_id+"/agriculteurs",{
            headers:{
                Authorization: "Bearer "+ JSON.parse(localStorage.getItem('data')).access
            }
        })
    return res.data
        
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error)
      }
  })
 

export const farmerSlice = createSlice({
  name: 'farmer',
  initialState,
  reducers: {
    
  },
  extraReducers: {
      [addFarmer.pending]: (state) => {
        state.loading = true
        state.farmerData = null
        state.farmerError = null
      },
      [addFarmer.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.farmerData = payload
      },
      [addFarmer.rejected]: (state,{payload}) => {
        state.loading = false
        state.farmerError = payload
      },
      [getFarmers.pending]: (state) => {
        state.loading = true
        state.farmers = null
        state.getFarmersError = null
      },
      [getFarmers.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.farmers = payload
      },
      [getFarmers.rejected]: (state,{payload}) => {
        state.loading = false
        state.getFarmersError = payload
      },
      [deleteFarmer.pending]: (state) => {
        state.loading = true
        state.deleteFarmerData = null
        state.deleteFarmerError = null
      },
      [deleteFarmer.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.deleteFarmerData = "ok"
      },
      [deleteFarmer.rejected]: (state,{payload}) => {
        state.loading = false
        state.deleteFarmerError = payload
      },
  }
})

// Action creators are generated for each case reducer function
export const farmerReducer = farmerSlice.reducer