import { Grid } from "@mui/material";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { members } from "../utils/members";
import AddButton from "./AddButton";
import MemberItem from "./MemberItem";
import MemberListItem from "./MemberListItem";

export default function MemberList({ data = [] }) {
  const { memberType } = useParams();
  if (memberType) {
    return (
      <>
        <p>{memberType}</p>
        <Grid container>
          {data.map((d, i) => (
            <MemberListItem memberData={d} key={i.toString} />
          ))}
        </Grid>
      </>
    );
  }
  return (
    <div>
      <Grid container>
        <Grid item sm={10}>
          <NavLink style={{ textDecoration: "none" }} to="/add-member">
            <AddButton title="Ajouter un membre" />
          </NavLink>
        </Grid>
        <Grid item sm={2}></Grid>

        {members.map((m, i) => (
          <MemberItem
            title={m.title}
            logoUrl={m.logoUrl}
            goTo={"/list-members/" + m.type}
            key={i.toString()}
          />
        ))}
      </Grid>
    </div>
  );
}
