import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from './slices/authSlice'
import { campainReducer } from './slices/campainSlice'
import { farmerReducer } from './slices/farmerSlice'
import { memberReducer } from './slices/memberSlice'

export const store = configureStore({
  reducer: {
      auth: authReducer,
      member: memberReducer,
      farmer: farmerReducer,
      campain: campainReducer
  },
})