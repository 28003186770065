import React, { useEffect, useRef, useState } from "react";
import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Chip, Divider, Grid, IconButton } from "@mui/material";
import { MdLocationOn } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { BiTrash } from "react-icons/bi";
import ItemInfo from "../components/ItemInfo";
import { useDispatch, useSelector } from "react-redux";
import { getFarmers } from "../slices/farmerSlice";

import { geolocated } from "react-geolocated";
import mapboxGl from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Fragment } from "react";
import GoBackBtn from "../components/GoBackBtn";
import { getCampains } from "../slices/campainSlice";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxGl.workerClass =require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiZmFrZXVzZXJnaXRodWIiLCJhIjoiY2pwOGlneGI4MDNnaDN1c2J0eW5zb2ZiNyJ9.mALv0tCpbYUPtzT7YysA2g",
});

const MyMap = ({ coords }) => {
  const [user, setUser] = useState(null);
  const [center, setCenter] = useState({ lat: 14.721944619647957, lng: -17.46626716197133 });
  const [campains, setCampains] = useState([])
  const [info, setInfo] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { farmers, getFarmersError, loading } = useSelector(
    (state) => state.farmer
  );

  const { getCampainsData, getCampainsError, loading:l } = useSelector(
    (state) => state.campain
  );

  const getCenter = () => {
    if (user) {
      return user.geometry.coordinates[0];
    } else if (coords) {
      return;
    }
    return;
  };

  useEffect(() => {
    if (user ) {
      
      let myCoords = user.properties.centroid;
      setCenter({ lng: myCoords.split(" ")[1].replace("(",""), lat: myCoords.split(" ")[2].replace(")","") });
    }else if(location.state?.properties?.farmer){
      let farmer = location.state?.properties?.farmer
      setCenter({ lat: farmer.geometry.coordinates[0][0][1], lng:farmer.geometry.coordinates[0][0][0] });
    } else if (location.state?.geometry?.coordinates) {
      setCenter({ lat: location.state.geometry.coordinates[0][0][1], lng:location.state.geometry.coordinates[0][0][0] });
    }else if (coords) {
      setCenter({ lat: coords.latitude, lng: coords.longitude });
    }
  }, [user, coords]);

  useEffect(() => {
    if (location.state?.allFarmers === true) {
      dispatch(getFarmers());
    }
  }, []);

  const lastUser = useRef(null);
  useEffect(() => {
    if (location.state?.user && location.state?.user !== lastUser.current) {
      dispatch(getCampains({farmerId: location.state?.user?.id}))
      setUser(location.state.user);
      lastUser.current = location.state.user;
    }
  }, [location.state]);

  const handleDraw = (features) => {
    if (!location.state?.user && !location.state?.allFarmers) {
      let state = {
        geometry: features[0],
        properties: location.state.properties
      };
      navigate(location.state?.sourcePage, {
        state,
      });
    }
  };

  const onDrawCreate = ({ features }) => {
    handleDraw(features);
  };

  const onDrawUpdate = ({ features }) => {
    handleDraw(features);
  };

  console.log("map", location.state);
  const mapRef = useRef(null);

  const polygonPaint = {
    "fill-color": "#55AE58",
    "fill-opacity": 0.5,
  };

  const polygonPaintLast = {
    "fill-color":  "blue",
    "fill-opacity": 0.5,
  };

  const AllShapesPolygonCoords = [
    [
      [-17.46626716197133, 14.721944619647957],
      [-17.466285266882437, 14.72177794522733],
      [-17.46600497604021, 14.72193229741896],
      [-17.46600832880165, 14.721775999610657],
      [-17.46626716197133, 14.721944619647957],
    ],
  ];

  const handleInfo = (f) => {
    if (info) {
      setInfo(null);
    } else {
      if (f) {
        setInfo(f);
        return;
      }
      setInfo(location.state.user);
    }
  };
  console.log(location.state)
  return (
    <div>
      <Map
        zoom={[20]}
        style="mapbox://styles/mapbox/satellite-streets-v11" // eslint-disable-line
        containerStyle={{
          height: "100vh",
          width: "100vw",
        }}
        ref={mapRef}
        center={center} //lat: 14.7217916, lng: -17.4660162
      >
        <img
          src="/assets/images/logo.png"
          alt="logo"
          style={{ left: 40, position: "absolute", zIndex: 1 }}
        />
        <GoBackBtn to={location.state?.sourcePage} state={{properties: location.state?.properties}}  />
        {info && (
          <div
            style={{
              width: 400,
              backgroundColor: "white",
              position: "absolute",
              zIndex: 1,
              right: 10,
              top: 80,
              borderRadius: 5,
              padding: 10,
            }}
          >
            <Grid container>
              <Grid item sm={5}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  {" "}
                  {info.properties?.prenom} {info.properties?.nom}
                </p>
              </Grid>
              <Grid item sm={3.5}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    color: "#55AE58",
                  }}
                >
                  {" "}
                  {info.properties?.numero_mobile}
                </p>
              </Grid>
              <Grid item sm={3.5}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div>
                    <IconButton>
                      <IoIosCall
                        size="14"
                        color="white"
                        style={{
                          backgroundColor: "#55AE58",
                          borderRadius: 100,
                          padding: 2,
                        }}
                      />
                    </IconButton>
                    <IconButton>
                      <FiEdit size={14} color="#F0B903" />
                    </IconButton>
                    <IconButton>
                      <BiTrash size={14} color="#FF4141" />
                    </IconButton>
                  </div>
                </div>
              </Grid>

              {/* One row */}
              <Grid sm={12}>
                <Divider />
              </Grid>
              <ItemInfo
                title={"Sexe"}
                value={info.properties.sexe}
                size={3.99}
              />
              <ItemInfo
                title={"Situation matrimoniale"}
                show
                value={info.properties.situation_matrimoniale}
                size={3.99}
              />
              <ItemInfo
                title={"Date de naissance"}
                show
                value={info.properties.date_naissance}
                size={3.99}
              />

              {/* One row */}
              <Grid sm={12}>
                <Divider />
              </Grid>
              <ItemInfo
                title={"Langue parlée"}
                value={info.properties.langue_parlee[0]}
                size={3.99}
              />
              <ItemInfo
                title={"adresse"}
                show
                value={info.properties.ville}
                size={7.99}
              />

              {/* One row */}
              <Grid sm={12}>
                <Divider />
              </Grid>
              <ItemInfo title={"Groupement"} value={"-"} size={3.99} />
              <ItemInfo
                title={"Mobile money"}
                show
                value={info.properties.numero_mobile_money}
                size={7.99}
              />

              {/* One row */}
              <Grid sm={12}>
                <Divider />
              </Grid>
              <ItemInfo title={"Habilités"} size={12}>
                <Chip
                  style={{
                    display: !info.properties.est_malentendant ? "" : "none",
                    marginLeft: 5,
                  }}
                  label="Entendre"
                  variant="outlined"
                />
                <Chip
                  style={{
                    display: info.properties.peut_composer_un_numero
                      ? ""
                      : "none",
                    marginLeft: 5,
                  }}
                  label="Composer"
                  variant="outlined"
                />
                <Chip
                  style={{
                    display: !info.properties.est_illettre ? "" : "none",
                    marginLeft: 5,
                  }}
                  label="Lire"
                  variant="outlined"
                />
              </ItemInfo>
              <Grid sm={12}>
                <Divider />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                width: "100%",
                marginTop: 20,
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={handleInfo}
              >
                Fermer
              </Button>
            </div>
          </div>
        )}

        {user && (
          <Fragment>
            <Layer
              onClick={() => handleInfo()}
              onTouchStart={() => handleInfo()}
              type="fill"
              paint={polygonPaint}
            >
              <Feature coordinates={user.geometry.coordinates} />
            </Layer>
              <Marker
                coordinates={center}
                anchor="bottom">
               <MdLocationOn onClick={() => setCenter({lng: user.properties.centroid.split(" ")[1].replace("(",""), lat: user.properties.centroid.split(" ")[2].replace(")","")})} size="100" color="red" />
              </Marker>
          </Fragment>
        )}


{location.state?.geometry?.coordinates && (
          <Fragment>
            <Layer
              onClick={() => handleInfo()}
              onTouchStart={() => handleInfo()}
              type="fill"
              paint={polygonPaintLast}
            >
              <Feature coordinates={location.state?.geometry?.coordinates} />
            </Layer>
              {/* <Marker
                coordinates={center}
                anchor="bottom">
               <MdLocationOn onClick={() => setCenter({lng: user.properties.centroid.split(" ")[1].replace("(",""), lat: user.properties.centroid.split(" ")[2].replace(")","")})} size="100" color="red" />
              </Marker> */}
          </Fragment>
        )}
       
       {location.state?.properties?.farmer && (
          <Fragment>
            <Layer
              type="fill"
              paint={polygonPaint}
            >
              <Feature coordinates={location.state?.properties?.farmer?.geometry?.coordinates} />
            </Layer>
  
          </Fragment>
        )}

      {getCampainsData && getCampainsData.features?.map((item,index) => (
          <Fragment key={index.toString()}>
            <Layer
              type="fill"
              paint={polygonPaintLast}
            >
              <Feature coordinates={item.geometry?.coordinates} />
            </Layer>
  
          </Fragment>
        ) )  }


        {location.state?.allFarmers &&
          farmers &&
          farmers.features &&
          farmers.features.map((f, i) => (
            <Fragment key={i.toString()} >
              <Marker
                coordinates={{lng: f.properties.centroid.split(" ")[1].replace("(",""), lat: f.properties.centroid.split(" ")[2].replace(")","")}}
                anchor="bottom">
               <MdLocationOn onClick={() => setCenter({lng: f.properties.centroid.split(" ")[1].replace("(",""), lat: f.properties.centroid.split(" ")[2].replace(")","")})} size="100" color="red" />
              </Marker>

            <Layer
              onTouchStart={() => handleInfo(f)}
              onClick={() => handleInfo(f)}
              type="fill"
              paint={polygonPaint}
            >
              <Feature coordinates={f.geometry.coordinates} />
            </Layer>
            </Fragment>
          ))}

          {/* <div style={{width: 200, height: 50}}>

          </div> */}
        <DrawControl onDrawCreate={onDrawCreate} onDrawUpdate={onDrawUpdate} />
      </Map>
    </div>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(MyMap);
