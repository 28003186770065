import React from "react";
import SideBars from "../components/SideBars";

function Account() {
  return (
    <SideBars/>
  );
}

export default Account;
