import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { LOGIN } from '../endpoints'

const initialState = {
  loading: false,
  loginData: null,
  loginError: null,

}

export const login = createAsyncThunk(
    'auth/login',
    async (data,{rejectWithValue}) => {
      try {
        const res = await axios.post(LOGIN,data)
    return res.data
        
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error)
      }
  })

 

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    
  },
  extraReducers: {
      [login.pending]: (state) => {
        state.loading = true
        state.loginData = null
        state.loginError = null
      },
      [login.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.loginData = payload
      },
      [login.rejected]: (state,{payload}) => {
        state.loading = false
        state.loginError = payload
      },
      
  }
})

// Action creators are generated for each case reducer function
export const authReducer = authSlice.reducer