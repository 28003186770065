export const topText = {
  "/dashboard": {
    title: "Dashboard",
    sub: null,
  },
  "/list-agency": {
    title: "Liste des agences",
    sub: null,
  },
  "/add-agency": {
    title: "Ajouter une agence",
    sub: null,
  },
  "/add-farmer": {
    title: "Ajouter un agriculteur",
    sub: null,
  },
  "/add-campain": {
    title: "Ajouter une campagne",
    sub: null,
  },
  "/list-farmers": {
    title: "Liste des agriculteurs",
    sub: null,
  },
  "/groupments": {
    title: "Liste des groupements",
    sub: null,
  },
  "/list-members": {
    title: "Liste des membres",
    sub: null,
  },
  
};
