import { Grid, TextField } from '@mui/material'
import React from 'react'

function Input({label,placeholder,sm,start, name, onChange, value,mt=10,type="text" }) {
    return (
      <Grid item sm={sm}  style={{flexDirection: "row", display: "flex"}}>
        <div style={{width: 80, height: 52, backgroundColor: "#7A7A7A", marginTop: 10, display: start ? "": "none", border: "solid #55AE58 2px"}}>
          <p style={{fontWeight: "bold", textAlign: "center", color:"white"}}>{start}</p>
        </div>
        <TextField
            style={{ ...styles.input, marginTop: mt }}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            fullWidth
            onChange={onChange}
            name={name}
            value={value}
            type={type}
            InputProps={{
              style: {
                fontWeight: "bold",
                borderTopLeftRadius: start ? 0 : "",
                borderBottomLeftRadius: start ? 0 : "",
              },
            }}
            />
      </Grid>
    )
}
const styles = {
    input: {
      marginTop: 20,
      fontSize: 20,
      fontWeight: "bold",
      color: "#9F9F9F",
      backgroundColor: "white"
    },
  };
export default Input
