import { useNavigate } from "react-router-dom";


export const agencyMenu = [
    {
      title: "Dashboard",
      link: "/dashboard",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
      
    },
    {
      title: "Comptes",
      link: "/list-agency",
      isActiveIn: ["/add-agency","/update-agency"],
      icon: "MdOutlineDashboard",
      topTitle: "Comptes"
    },
    {
      title: "Services",
      link: "/services",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
    },
    {
      title: "Packs",
      link: "/packs",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
    },
    {
      title: "Points focaux",
      link: "/points-focaux",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
    },
    {
      title: "Acteurs externes",
      link: "/acteurs-externes",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
    },
  ];

  export const farmerMenu = [
    {
      title: "Dashboard",
      link: "/map",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
      state:  {
        allFarmers: true,
      },
    },
    {
      title: "Agriculteurs",
      link: "/list-farmers",
      isActiveIn: ["/add-farmer","/update-farmer","/add-campain"],
      icon: "MdOutlineDashboard",
    },
    {
      title: "Groupements",
      link: "/groupments",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
    },
    {
      title: "Membres",
      link: "/list-members",
      isActiveIn: ["/add-member"],
      icon: "MdOutlineDashboard",
    },
    {
      title: "Projets",
      link: "/points-focaux",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
    },
    {
      title: "Services",
      link: "/acteurs-externes",
      isActiveIn: [],
      icon: "MdOutlineDashboard",
    },
    {
        title: "Analyse, Suivi et évaluation",
        link: "/acteurs-externes",
        isActiveIn: [],
        icon: "MdOutlineDashboard",
      },
  ];
  